// config.js
const config = {
  // FRONTEND_URL: "https://becoming.neuronsit.in",
  // BACKEND_URL: "https://byb.neuronsit.in",

  // FRONTEND_URL: "http://localhost:3000",
  // BACKEND_URL: "http://localhost:8000",

  FRONTEND_URL: "https://becomingyourbestplanner.com",
  BACKEND_URL: "https://admin.becomingyourbestplanner.com",

  APP_NAME: "Becoming Your Best",
  GOOGLE_CLIENTID:
    "906061500366-qovb9nvr7o5qmbl3jj0o9ju3a0grcup9.apps.googleusercontent.com",

  // STRIPE_PUBLISHABLE_KEY: "pk_test_Pyu4oj7fU3CFtbcP0gH16Ila",
  // STRIPE_SECRET_KEY: "sk_test_y70bYOyeXF1jzAkFnEXLrbau",

  STRIPE_PUBLISHABLE_KEY: "pk_live_05dLszUzqkaZODyXFJbpoITe",
  STRIPE_SECRET_KEY:
    "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",

  // FRIENDBUY_MERCHANT_ID: "13b7e219-985d-4f05-b176-0ea87432f6ee",
  // FRIENDBUY_SECRET_KEY: "3775a44a-9b0a-430f-a54a-5e2eb9006192",
};

export default config;
