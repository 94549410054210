import React, { useState, useEffect } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";
import "./style.css";

import axios from "axios";
import config from "../../../config";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
//component
import NavBar from "../../NavBar";
import Footer from "../../Footer";
import MyAccountMenu from "../../../Components/MyAccountMenu";
import { ButtonPrimary } from "../../../Components/Buttons";

export default function ManageAddress() {
  const BACKEND_URL = config.BACKEND_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const [myUser, setMyUser] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const currentRoute = location.pathname;
  const CountryList = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegowina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, the Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia (Hrvatska)",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "France Metropolitan",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard and Mc Donald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao, People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia, The Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia (Slovak Republic)",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "St. Helena",
    "St. Pierre and Miquelon",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen Islands",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna Islands",
    "Western Sahara",
    "Yemen",
    "Yugoslavia",
    "Zambia",
    "Zimbabwe",
  ];
  const StateList = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const userSession = localStorage.getItem("User");
  const apiToken = localStorage.getItem("Token");
  const ApiHeaders = {
    Authorization: `Bearer ${apiToken}`,
    "Content-Type": "application/json", // Include this header if needed
  };

  const [addressListData, setAddressListData] = useState([]);
  const [activeTab, setActiveTab] = useState("MY-ACCOUNT");
  const [sideMenu, setSideMenu] = useState(false);
  const [userData, setUserData] = useState([]);
  const [stateDrop, setStateDrop] = useState(false);
  const [stateDropVal, setStateDropVal] = useState("");
  const [countryDrop, setCountryDrop] = useState(false);
  const [countryDropVal, setCountryDropVal] = useState("United States");
  const [streetError, setStreetError] = useState(false);
  const [houseError, setHouseError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [stateDropValError, setStateDropValError] = useState(false);
  const [countryDropValError, setCountryDropValError] = useState(false);
  const [addressBookForm, setAddressBookForm] = useState(false);
  const [houseNo, setHouseNo] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [addressId, setAddressId] = useState("");

  const [addressBooktLog, SetAddressBookLog] = useState({
    houseNo: "",
    street: "",
    city: "",
    zipcode: "",
    stateDropVal: "",
    countryDropVal: "United States",
  });
  const handleCountry = (e) => {
    setCountryDrop(false);
    setCountryDropVal(e.target.innerText);
  };

  useEffect(() => {
    if (userSession != undefined) {
      const userSessionData = JSON.parse(userSession);
      setMyUser(userSessionData);
      setIsLoggedIn(true);
    }

    if (userSession !== undefined && userSession !== null) {
      axios
        .get(`${BACKEND_URL}/api/V1/myaccount`, { headers: ApiHeaders })
        .then((response) => {
          const { User, AddressData } = response.data.result;
          setAddressListData(AddressData);
          setUserData(User);
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    } else {
      navigate("/");
    }
  }, []);

  const handleSaveAddress = (e) => {
    setAddressBookForm(true);
    if (
      houseNo === "" ||
      street === "" ||
      city === "" ||
      stateDropVal === "" ||
      zipcode === "" ||
      countryDropVal === ""
    ) {
      if (houseNo === "") setHouseError(true);
      else setHouseError(false);
      if (street === "") setStreetError(true);
      else setStreetError(false);
      if (city === "") setCityError(true);
      else setCityError(false);
      if (stateDropVal === "") setStateDropValError(true);
      else setStateDropValError(false);
      if (zipcode === "") setZipcodeError(true);
      else setZipcodeError(false);
      if (countryDropVal === "") setCountryDropValError(true);
      else setCountryDropValError(false);

      toast("Please fill the required fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const postData = {
        id: addressId,
        houseno: houseNo,
        street: street,
        city: city,
        state: stateDropVal,
        zipcode: zipcode,
        country: countryDropVal,
        // user_id : myUser.id
      };
      console.log(postData);
      axios
        .post(`${BACKEND_URL}/api/V1/updateAddressInfo`, postData, {
          headers: ApiHeaders,
        })
        .then((response) => {
          if (response.data.status) {
            const { AddressData } = response.data.result;
            SetAddressBookLog({
              houseNo: "",
              street: "",
              city: "",
              stateDropVal: "",
              zipcode: "",
              countryDropVal: "United States",
            });
            setAddressListData(AddressData);
            setAddressBookForm(false);
            toast(response.data.result.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast(response.data.errors, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          toast(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  const handleDeleteAddress = (addressId) => {
    if (userSession !== undefined && userSession !== null) {
      const postData = {
        id: addressId,
      };
      axios
        .post(`${BACKEND_URL}/api/V1/deleteAddressInfo`, postData, {
          headers: ApiHeaders,
        })
        .then((response) => {
          if (response.data.status) {
            const { AddressData } = response.data.result;
            setAddressListData(AddressData);

            toast(response.data.result.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast(response.data.errors, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    } else {
      navigate("/");
    }
  };

  const handleEditAddress = (addressDetail) => {
    setAddressBookForm(true);
    setAddressId(addressDetail.id);
    setHouseNo(addressDetail.houseno);
    setStreet(addressDetail.street);
    setCity(addressDetail.city);
    setStateDropVal(addressDetail.state);
    setZipcode(addressDetail.zipcode);
    setCountryDropVal(addressDetail.country);
  };

  const handleAddAddressForm = () => {
    setAddressBookForm(true);
    setAddressId("");
    setHouseNo("");
    setStreet("");
    setCity("");
    setStateDropVal("");
    setZipcode("");
    setCountryDropVal("United States");
  };

  return (
    <>
      <NavBar />
      <Box className="myAccountContainer">
        <Box className="myAccountHeader">
          <Typography className="myAccountHeaderText">
            Hello, {userData.first_name}{" "}
            {userData.last_name != null ? userData.last_name : ""}
          </Typography>
          <Box className="myAccountHeaderNavigation">
            <Typography mr={1}>HOME / </Typography>
            <Typography mr={1}>ACCOUNT / </Typography>
            <Typography mr={1}>MANAGE ADDRESS </Typography>
          </Box>
        </Box>

        <Box className="myAccountInfoBox">
          <MyAccountMenu />

          <Box className="myAccountDetailsBox">
            {/* my account info  */}
            <Box className="myAccountTab">
              <SwapHorizontalCircleOutlinedIcon
                onClick={() => setSideMenu(!sideMenu)}
                className="sideMenuIcon"
              />

              <Box className="myAccountTabItem myAccountTabSubYSpacing2">
                <Typography className="myAccountTabHeader">
                  ADDRESS BOOK
                </Typography>
                <Typography
                  className="myAccountTabLinkText"
                  onClick={() => handleAddAddressForm()}
                >
                  ADD ADDRESS
                </Typography>
              </Box>

              <Box className="addressBookTab addressForm gap">
                <Box
                  sx={{ display: addressBookForm ? "none" : "flex" }}
                  className="AddressList"
                >
                  {addressListData.map((address, index) => (
                    <Box className="addressRow">
                      <Typography>{index + 1}. </Typography>
                      <Typography>
                        {address.houseno} {address.street}, {address.city}{" "}
                        {address.state}, {address.country} - {address.zipcode}
                      </Typography>
                      <Typography
                        className="addressTabLinkText"
                        onClick={() => handleEditAddress(address)}
                      >
                        Edit
                      </Typography>
                      <Typography
                        className="addressTabLinkText"
                        onClick={() => handleDeleteAddress(address.id)}
                      >
                        Delete
                      </Typography>
                    </Box>
                  ))}
                </Box>

                <Box
                  sx={{ display: addressBookForm ? "flex" : "none" }}
                  className="addUserAddressBox"
                >
                  <Box className="address-row">
                    <Box mb={2} className="addressBookInputBox">
                      <Typography>House No *</Typography>
                      <TextField
                        className={`Ffour ${streetError ? "required" : ""}`}
                        placeholder="Street"
                        tabIndex="5"
                        value={houseNo}
                        onChange={(e) => {
                          setHouseNo(e.target.value);
                        }}
                      />
                      <input type="hidden" value={addressId} />
                    </Box>
                    <Box mb={2} className="addressBookInputBox">
                      <Typography>Street *</Typography>
                      <TextField
                        className={`Ffour ${houseError ? "required" : ""}`}
                        placeholder="Ste, apt, etc"
                        tabIndex="5"
                        value={street}
                        onChange={(e) => {
                          setStreet(e.target.value);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="address-row">
                    <Box mb={2} className="addressBookInputBox">
                      <Typography>City *</Typography>
                      <TextField
                        className={`Ffour ${cityError ? "required" : ""}`}
                        placeholder="City"
                        tabIndex="5"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      />
                    </Box>
                    <Box mb={2} className="addressBookInputBox">
                      <Typography>State *</Typography>
                      {countryDropVal != "United States" && (
                        <TextField
                          className={`Ffour ${
                            stateDropValError ? "required" : ""
                          }`}
                          placeholder="State"
                          tabIndex="5"
                          value={stateDropVal}
                          onChange={(e) => {
                            setStateDropVal(e.target.value);
                          }}
                        />
                      )}
                      {countryDropVal == "United States" && (
                        <TextField
                          className={`Ffour ${
                            stateDropValError ? "required" : ""
                          }`}
                          placeholder="State"
                          tabIndex="5"
                          value={stateDropVal}
                        />
                      )}

                      {countryDropVal == "United States" && (
                        <ArrowDropDownIcon
                          onClick={() => setStateDrop(!stateDrop)}
                          className="stateDropIcon"
                        />
                      )}
                      {countryDropVal == "United States" && (
                        <Box
                          sx={{ height: stateDrop ? "auto" : "0px" }}
                          className="stateDrop"
                        >
                          {StateList.map((state) => (
                            <Box
                              onClick={(e) => {
                                setStateDrop(false);
                                setStateDropVal(e.target.innerText);
                              }}
                              className="stateDropItem"
                            >
                              <Typography>{state}</Typography>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box className="address-row">
                    <Box mb={2} className="addressBookInputBox">
                      <Typography>Zipcode *</Typography>
                      <TextField
                        className={`Ffour ${zipcodeError ? "required" : ""}`}
                        placeholder="90001"
                        tabIndex="5"
                        value={zipcode}
                        onChange={(e) => {
                          setZipcode(e.target.value);
                        }}
                      />
                    </Box>
                    <Box mb={2} className="addressBookInputBox">
                      <Typography>Country *</Typography>
                      <TextField
                        className={`Ffour ${
                          countryDropValError ? "required" : ""
                        }`}
                        placeholder="Country"
                        tabIndex="10"
                        type="dropDown"
                        value={countryDropVal}
                      />
                      <ArrowDropDownIcon
                        onClick={() => setCountryDrop(!countryDrop)}
                        className="stateDropIcon"
                      />
                      <Box
                        sx={{ height: countryDrop ? "auto" : "0px" }}
                        className="stateDrop"
                        tabIndex="11"
                      >
                        {CountryList.map((country) => (
                          <Box
                            onClick={handleCountry}
                            className="stateDropItem"
                          >
                            <Typography>{country}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="address-row">
                    <Box mb={2} className="addressBookInputBox">
                      <Box
                        sx={{ width: "100%" }}
                        className="button"
                        onClick={handleSaveAddress}
                      >
                        <Typography sx={{ fontSize: "18px" }}>Save</Typography>
                      </Box>
                    </Box>
                    <Box mb={2} className="addressBookInputBox">
                      <Box
                        sx={{ width: "100%" }}
                        className="button"
                        onClick={() => setAddressBookForm(false)}
                      >
                        <Typography sx={{ fontSize: "18px" }}>
                          Cancel
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
      <ToastContainer autoClose={false} draggable={false} />
    </>
  );
}
