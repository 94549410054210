import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';

import axios from 'axios';
import config from '../../config';
import "./style.css"


import FooterLogo from "../../Assets/images/BYBnewtag(white)-PLANNER.png"
import PinterestIcon from "../../Assets/images/Pinterest.svg"
import InstagramIcon from "../../Assets/images/Instagram.svg"
import TiktokIcon from "../../Assets/images/Tiktok.svg"
import FacebookIcon from "../../Assets/images/Facebook.svg"

import sendArrowIcon from "../../Assets/images/sendArrowIcon.svg"

export default function Footer({ extraClass }) {
  const BACKEND_URL = config.BACKEND_URL;

  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [subscribeEmailError, setSubscribeEmailError] = useState("");

  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubscribe = ()=>{
    setSubscribeEmailError('');
    //setSubscribed(false);
    if (emailIsValid(subscribeEmail)) {


      axios.post(`${BACKEND_URL}/api/V1/subscribe/`, {subscribeEmail} )
        .then(response => {
          const { message } = response.data.result;
          setSubscribeEmail('');
          toast(message,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });

        })
        .catch(error => {
          console.error('Error fetching layout data:', error);
        });
      
    } else {
      setSubscribeEmailError('Please enter a valid email address.');
    }
  }

  const handleSubcribeEnter = (e) => {
    if (e.key === 'Enter') {
      handleSubscribe();
    }
  };

  return (
    <>
      <Box className={`Footer ${extraClass}`}>
        <Box className="footerMainBox">
          <Box className="footerItemBox">
            <Box className="footerItemSubBox companyLogoBox">
              <Link to="https://becomingyourbestplanner.com"><img className='FooterLogo' src={FooterLogo} alt='FooterLogo' /></Link>
            </Box>
            <Box className="footerItemSubBox FooterCompanyIconBox">
              <Box className="FooterCompanyIcon">
                <Link to="https://pin.it/4i8GqiBKj"><img src={PinterestIcon} alt='PinterestIcon' /></Link>
              </Box>
              <Box className="FooterCompanyIcon">
                <Link to="https://www.instagram.com/becomingyourbestplanner/"><img src={InstagramIcon} alt='InstagramIcon' /></Link>
              </Box>
              
              <Box className="FooterCompanyIcon">
                <Link to="https://www.facebook.com/profile.php?id=61551543479478&mibextid=PtKPJ9
"><img src={FacebookIcon} alt='FacebookIcon' /></Link>
              </Box>
              <Box className="FooterCompanyIcon">
                <Link to="https://www.tiktok.com/@becomingyourbestplanner"><img src={TiktokIcon} alt='TiktokIcon' /></Link>
              </Box>
            </Box>
          </Box>

          <Box className="footerItemBox">
            <Box className="footerItemSubBox">
              <Link to="/about-us"><Typography>About us</Typography></Link>
            </Box>
            <Box className="footerItemSubBox">
              <Link to="/faqs"><Typography>FAQ</Typography></Link>
            </Box>
            <Box className="footerItemSubBox">
              <Link to="/contact-us"><Typography>Contact us</Typography></Link>
            </Box>
          </Box>


          <Box className="footerItemBox">
            <Box className="footerItemSubBox">
              <Link to="/shipping-return"><Typography>Shipping / Returns</Typography></Link>
            </Box>
            <Box className="footerItemSubBox">
              <Link to="/privacy-policy"><Typography>Privacy policy</Typography></Link>
            </Box>
            <Box className="footerItemSubBox">
              <Link to="/terms-conditions"><Typography>Terms of service</Typography></Link>
            </Box>
          </Box>


          <Box className="footerItemBox">
            <Box className="footerItemSubBox">
              <Typography className='poppinsBold'>Subscribe</Typography>
            </Box>

            {subscribeEmailError && <Typography className='SubscribeErrorMsg'>{subscribeEmailError}</Typography>}
            
            <Box className="footerItemSubBox">
              <input className="footerMailInput" placeholder='Your email address' value={subscribeEmail} onChange={(e) =>{ setSubscribeEmail(e.target.value)}} onKeyPress={handleSubcribeEnter} />
              
              <img className='sendArrowIcon' src={sendArrowIcon} alt='SendArrowIcon' onClick={handleSubscribe} />
            </Box>
            
            <Box className="footerItemSubBox footerCheckBox">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_2991_5483)">
                  <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM17.99 9L16.58 7.58L9.99 14.17L7.41 11.6L5.99 13.01L9.99 17L17.99 9Z" fill="#E4E5E1" />
                </g>
                <defs>
                  <clipPath id="clip0_2991_5483">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography>Receive a 12-part series on get the most out<br /> of any planner.</Typography>
            </Box>
            <Box className="footerItemSubBox footerCheckBox">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_2991_5483)">
                  <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM17.99 9L16.58 7.58L9.99 14.17L7.41 11.6L5.99 13.01L9.99 17L17.99 9Z" fill="#E4E5E1" />
                </g>
                <defs>
                  <clipPath id="clip0_2991_5483">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography>Subscribe to our weekly productivity, well- <br />being, and leadership newsletter</Typography>
            </Box>
          </Box>





        </Box>

        <Box className="footerBottomBox">
          <Typography>Copyright 2023, Becoming Your Best Global Leadership</Typography>
        </Box>

      </Box>
      <ToastContainer autoClose={false} draggable={false} />
    </>
  )
}
