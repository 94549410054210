import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import "./style.css";

import GTic from "../../Assets/images/GTic.png (1).svg";

export default function LayoutTabs({ selectedTab, updateTab }) {
  const [tab, setTab] = useState("");

  useEffect(() => {
    setTab(selectedTab);
  }, []);

  const handleTab = (tabname) => {
    setTab(tabname);
    updateTab(tabname);
  };

  return (
    <>
      <Box className="LeftHeader">
        <Typography className="LeftTitle">Layouts</Typography>
        <Typography className="LeftSubText">
          Choose your layout and customize it below
        </Typography>
      </Box>

      <Box className="pagetab-list">
        <Box
          className={tab === "WeeklyView" ? "pagetab active" : "pagetab"}
          onClick={() => handleTab("WeeklyView")}
        >
          <img
            style={{ display: tab === "WeeklyView" ? "block" : "none" }}
            className="GTic"
            src={GTic}
            alt="close"
          />
          <Typography>Weekly</Typography>
        </Box>
        <Box
          className={tab === "DailySinglePage" ? "pagetab active" : "pagetab"}
          onClick={() => handleTab("DailySinglePage")}
        >
          <img
            style={{ display: tab === "DailySinglePage" ? "block" : "none" }}
            className="GTic"
            src={GTic}
            alt="close"
          />
          <Typography>Daily</Typography>
        </Box>
        <Box
          className={tab === "DailyTwoPage" ? "pagetab active" : "pagetab"}
          onClick={() => handleTab("DailyTwoPage")}
        >
          <img
            style={{ display: tab === "DailyTwoPage" ? "block" : "none" }}
            className="GTic"
            src={GTic}
            alt="close"
          />
          <Typography>Daily 2P</Typography>
        </Box>
      </Box>
    </>
  );
}
