import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import "./DailySinglePage/style.css";

//Component
import NavBar from "../NavBar";
import Footer from "../Footer";

// pages
import WeeklyView from "./WeeklyView";
import DailySinglePage from "./DailySinglePage";
import DailyTwoPage from "./DailyTwoPage";

export default function Layout() {
  const location = useLocation();
  const hashValue = location.hash.replace("#", "");

  const [tab, setTab] = useState("");
  const [priceBox, setPriceBox] = useState(1);
  const [menuPopInfo, setMenuPopInfo] = useState(false);

  useEffect(() => {
    var defaultParameters = "WeeklyView";

    if (!sessionStorage.getItem("layoutpopup")) {
      setMenuPopInfo(true);
    }

    var sessionData = JSON.parse(sessionStorage.getItem("Layout"));

    if (sessionData !== null) {
      if (sessionData.hasOwnProperty("DailySinglePage")) {
        setTab("DailySinglePage");
      }
      if (sessionData.hasOwnProperty("DailyTwoPage")) {
        setTab("DailyTwoPage");
      }

      if (sessionData.hasOwnProperty("WeeklyView")) {
        setTab("WeeklyView");
      }
      if (hashValue != "") {
        setTab(hashValue);
      }
    } else {
      setTab(defaultParameters);
      if (hashValue != "") {
        setTab(hashValue);
      }
    }
  }, []);

  const handleTab = (tabname) => {
    setTab(tabname);
  };

  const handlePricebox = () => {
    setPriceBox(priceBox + 1);
  };

  const handleNextUrl = (tab) => {
    if (tab == "Calendars") {
      handleTab("Calendars");
    }
  };

  const closePopup = () => {
    sessionStorage.setItem("layoutpopup", "true");
    setMenuPopInfo(false);
  };

  return (
    <>
      <NavBar updatePriceBox={priceBox} nextUrl={handleNextUrl} />
      <Box className="PageContainer">
        <Box className="PageBox">
          {tab === "WeeklyView" ? (
            <WeeklyView
              updatePriceBox={handlePricebox}
              updateTabPage={handleTab}
            />
          ) : null}
          {tab === "DailySinglePage" ? (
            <DailySinglePage
              updatePriceBox={handlePricebox}
              updateTabPage={handleTab}
            />
          ) : null}
          {tab === "DailyTwoPage" ? (
            <DailyTwoPage
              updatePriceBox={handlePricebox}
              updateTabPage={handleTab}
            />
          ) : null}
        </Box>

        <Box className={menuPopInfo ? "PopInfo arrow-top open" : "PopInfo"}>
          <Box className="PopInfo-wrapper">
            <Typography className="PopInfoClose" onClick={closePopup}>
              X
            </Typography>
            <Typography className="PopInfoText">
              <span>Three Layout Options</span>
              Would you like a weekly view calendar, daily one page calendar, or
              daily two-page calendar? Use the submenu above to explore the
              three layout options.
            </Typography>
            <Typography className="PopInfoText">
              <span>Customize Your Layout</span>
              Select your layout option, and then use the design bar on the left
              to modify and customize it to your liking (e.g., select no times,
              earlier or later times, different “to do” list options, etc.).
            </Typography>
            <Typography className="PopInfoText">
              <span>Select Monthly and Yearly Calendars</span>
              After you select your layout and hit “next,” you can add monthly
              and year-at-a-glance calendars in the “Calendar” page.
            </Typography>
            <Typography className="PopInfoText">
              Live rad and have fun! 🤓
            </Typography>

            <Box className="PopInfoBtnClose button" onClick={closePopup}>
              <p>Close</p>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
